import GridSize from './Cordinates/GridSize.js';
import GridPoint from './Cordinates/GridPoint.js';

export default {
  // Clean instance of connector object
  EMPTY_CONNECTOR: {
    gridPosition: null,
    connectingElements: [],
    connectingElementConnections: [],
    spacers: {},
    placeholder: false, // if connector comes with a element (ladder, fences and pile cages)
    isAccessory: false, // if this is acutaly an element acting as connector
    hasSidebumper: false,
    hasMooring: false,
    color: null,
    item: null,
    oldName: null, // required when redrawing connectors on delete
  },

  EMPTY_ITEM_IN_CONNECTION: {
    connections: null,
    name: null,
    rule: null,
    size: null,
    sku: {},
    type: null,
  },

  MODES: {
    INSERT: 'insert',
    DELETE: 'delete',
    INSERT_TEXT: 'insert_text',
    MASS_INSERT: 'mass_insert',
    MASS_DELETE: 'mass_delete',
  },

  BASE_ELEMENT: 'basic_elements',
  EXTRA_ELEMENT: 'accessories',
  CONNECTOR_ELEMENT: 'connectors',
  TEXT_ELEMENT: 'text',

  ORIENTATION_LEFT: 0,
  ORIENTATION_TOP: 1,
  ORIENTATION_RIGHT: 2,
  ORIENTATION_BOTTOM: 3,

  // Primary elements & connectors
  RULESET_SINGLE_ELEMENT: 'single_element',
  RULESET_DOUBLE_ELEMENT: 'double_element',
  RULESET_CONNECTING_PIN_SHORT: 'connecting_pin_short',
  RULESET_CONNECTING_PIN_LONG: 'connecting_pin_long',
  RULESET_SIDE_SCREW_WITH_NUT_LONG: 'side_screw_with_nut_long',
  RULESET_SIDE_SCREW_290: 'side_screw_290',
  RULESET_SIDE_SCREW: 'side_screw',

  // Sidebumpers
  RULESET_SIDE_BUMPER: 'side_bumper',
  RULESET_SIDE_BUMPER_3: 'side_bumper_3',
  RULESET_SIDE_BUMPER_4: 'side_bumper_4',
  RULESET_SIDE_BUMPER_5: 'side_bumper_5',
  RULESET_SIDE_BUMPER_6: 'side_bumper_6',
  RULESET_SIDE_BUMPER_7: 'side_bumper_7',
  RULESET_BOAT_FENDER_WITH_NUT: 'boat_fender_with_nut',

  RULESET_SIDE_BUMPER_CORNER_OUTER_0: "side_bumper_corner_outer_0",
  RULESET_SIDE_BUMPER_CORNER_OUTER: "side_bumper_corner_outer",
  RULESET_SIDE_BUMPER_CORNER_OUTER_3: "side_bumper_corner_outer_3",
  RULESET_SIDE_BUMPER_CORNER_OUTER_4: "side_bumper_corner_outer_4",

  RULESET_SIDE_BUMPER_CORNER_INNER_0: "side_bumper_corner_inner_0",
  RULESET_SIDE_BUMPER_CORNER_INNER: "side_bumper_corner_inner",
  RULESET_SIDE_BUMPER_CORNER_INNER_3: "side_bumper_corner_inner_3",
  RULESET_SIDE_BUMPER_CORNER_INNER_4: "side_bumper_corner_inner_4",

  // Ladders and slides
  RULESET_LADDER: 'ladder',
  RULESET_BATHING_SLIDE: 'bathing_slide',

  // Screw Joints
  RULESET_BOAT_CLEAT: 'boat_cleat',
  RULESET_SCREW_JOINT_UNIT: 'screw_joint_unit',
  RULESET_BOAT_MOUNTING: 'boat_mounting',
  RULESET_CROSS_BOLLARD_EDGE: 'cross_bollard_edge',
  RULESET_CROSS_BOLLARD_PIN: 'cross_bollard_pin',
  RULESET_CONNECTING_PIN_WITH_STEEL: 'connecting_pin_with_steel',
  RULESET_GUIDING_SLEEVE_UMBRELLAS: 'guiding_sleeve_umbrellas',
  RULESET_FLAG_POLE: 'flag_pole',
  RULESET_SCREW_JOINT_UNIT_SPECIAL: "screw_joint_unit_special",

  RULESET_PILE_CAGE_FOR_TUBES_OUTER: "pile_cage_for_tubes_outer",
  RULESET_PILE_CAGE_GLIDING_SYSTEM_OUTER: "pile_cage_gliding_system_outer",
  RULESET_PILE_CAGE_MAX_50_OUTER: "pile_cage_max_50_outer",
  RULESET_PILE_CAGE_MAX_500_OUTER: "pile_cage_max_500_outer",

  RULESET_PILE_CAGE_MAX_230_OUTER: "pile_cage_max_230_outer",
  RULESET_PILE_CAGE_MAX_300_OUTER: "pile_cage_max_300_outer",
  RULESET_PILE_CAGE_MAX_300_OUTER_3: "pile_cage_max_300_outer_3",

  RULESET_PILE_CAGE_310_INNER: "pile_cage_310_inner",
  RULESET_PILE_CAGE_STANDARD_INNER: "pile_cage_standard_inner",
  RULESET_PILE_CAGE_600_INNER: "pile_cage_600_inner",

  MOORING_DEVICE_0: 'mooring_device_0',
  MOORING_DEVICE_2: 'mooring_device_2',
  MOORING_DEVICE_3: 'mooring_device_3',
  STANCHION_CORNER: "stanchion_corner",
  STANCHION_WITH_BRACE: "stanchion_with_brace",
  STANCHION: "stanchion",
  STANCHION_WITH_ARC: "stanchion_with_arc", // 100cm
  STANCHION_WITH_ARC_150: "stanchion_with_arc_150cm",
  STANCHION_WITH_ARC_200: "stanchion_with_arc_200cm",
  STANCHION_INSIDE: "stanchion_inside",

  // Winches
  WINCH: "winch",

  ELEMENT_SIZES: {
    "single_element": new GridSize(1, 1),
    "double_element": new GridSize(1, 2),
    "connector": new GridSize(0.3, 0.3),
    "connecting_pin_short": new GridSize(0.3, 0.3),
    "side_screw": new GridSize(0.3, 0.3),
    "connecting_pin_long": new GridSize(0.3, 0.3),
    "side_screw_with_nut_long": new GridSize(0.3, 0.3),
    "side_screw_290": new GridSize(0.3, 0.3),

    // Accessories with rules
    // Outer Screw Joint
    "boat_cleat": new GridSize(0.3, 0.3),                 // outside only, remove sidescrews, fill the full height of connection
    "screw_joint_unit": new GridSize(0.3, 0.3),           // outside only, remove sidescrews, fill the full height of connection
    "cross_bollard_edge": new GridSize(0.3, 0.3),         // outside only, remove sidescrews, fill the full height of connection

    // Inner Screw Joint
    "boat_mounting": new GridSize(0.3, 0.3),              // inside only, remove pin, fill the full height of connection
    "cross_bollard_pin": new GridSize(0.3, 0.3),          // inside only, remove pin, fill the full height of connection
    "guiding_sleeve_umbrellas": new GridSize(0.3, 0.3),   // inside only, remove pin, fill the full height of connection
    "flag_pole": new GridSize(0.3, 0.3),                  // inside only, remove pin, fill the full height of connection

    // Special screw joint
    "screw_joint_unit_special": new GridSize(0.3, 0.3),  // connections noly, remove pin/sidescrew, fill the full height of connection

    "connecting_pin_with_steel": new GridSize(0.3, 0.3),  // inside only, leave pin

    "pile_cage_for_tubes_outer": new GridSize(1, 1),          // outside only, sidescrews=none, fill the full height of connection (2L)
    "pile_cage_gliding_system_outer": new GridSize(1, 1),          // outside only, sidescrews=none, fill the full height of connection (2L)
    "pile_cage_max_50_outer": new GridSize(0.3, 0.3),                // outside only, sidescrews=none, fill the full height of connection (0L)
    "pile_cage_max_500_outer": new GridSize(3, 2),              // outside only, sidescrews=none, fill the full height of connection (3L) 
    "pile_cage_max_230_outer": new GridSize(1, 1),                // outside only, sidescrews=none, fill the full height of connection (2L)
    "pile_cage_max_300_outer": new GridSize(1, 1),                // outside only, sidescrews=none, fill the full height of connection (2L)
    "pile_cage_max_300_outer_3": new GridSize(1, 2),                // outside only, sidescrews=none, fill the full height of connection (2L)
    "pile_cage_310_inner": new GridSize(1, 1),                // outside only, sidescrews=none, fill the full height of connection (4L)
    "pile_cage_standard_inner": new GridSize(1, 1),                // outside only, sidescrews=none, fill the full height of connection (4L)
    "pile_cage_600_inner": new GridSize(2, 2),                // outside only, sidescrews=none, fill the full height of connection (8L)
    
    
    "side_bumper": new GridSize(1, 1),    // outside only, add sidescrews to connections, fill the full height of connection (2L)
    "side_bumper_3": new GridSize(1, 2),  // outside only, add sidescrews to connections, fill the full height of connection (3L)
    "side_bumper_4": new GridSize(1, 3),  // outside only, add sidescrews to connections, fill the full height of connection (4L)
    "side_bumper_5": new GridSize(1, 4),  // outside only, add sidescrews to connections, fill the full height of connection (5L)
    "side_bumper_6": new GridSize(1, 5),  // outside only, add sidescrews to connections, fill the full height of connection (6L)
    "side_bumper_7": new GridSize(1, 6),  // outside only, add sidescrews to connections, fill the full height of connection (7L)
    "boat_fender_with_nut": new GridSize(0.3, 0.3), // outside only, fill the full height of connection
    "side_bumper_corner_outer_0": new GridSize(0.3, 0.3), // outside only, add sidescrews to connections, fill the full height of connection (0L)
    "side_bumper_corner_outer": new GridSize(1,1), // outside only, add sidescrews to connections, fill the full height of connection (2L)
    "side_bumper_corner_outer_3": new GridSize(1,1), // outside only, add sidescrews to connections, fill the full height of connection (3L)
    "side_bumper_corner_outer_4": new GridSize(1,1), // outside only, add sidescrews to connections, fill the full height of connection (4L)

    "side_bumper_corner_inner_0": new GridSize(0.3, 0.3), // inside only, add sidescrews to connections, fill the full height of connection (0L)
    "side_bumper_corner_inner": new GridSize(1,1), // inside only, add sidescrews to connections, fill the full height of connection (2L)
    "side_bumper_corner_inner_3": new GridSize(1,1), // inside only, add sidescrews to connections, fill the full height of connection (3L)
    "side_bumper_corner_inner_4": new GridSize(1,1), // inside only, add sidescrews to connections, fill the full height of connection (4L)



    "ladder": new GridSize(1, 1), //outside only, remove pins
    "bathing_slide": new GridSize(1, 8), //outside only, remove pins

    "mooring_device_0": new GridSize(0.3, 0.3), // inside only, leave pin, placed under the platform
    "mooring_device_2": new GridSize(1, 2), // inside only, leave pin, placed under the platform
    "mooring_device_3": new GridSize(1, 3), // inside only, leave pin, placed under the platform

    // NOTE: To check where the connection points are cacluated from check the image in publuic folder
    "stanchion_corner": new GridSize(1, 1),     // anywhere, replace the connectors with itself, fill the full height of connection
    "stanchion_with_brace": new GridSize(1, 1), // anywhere, replace the connectors with itself, fill the full height of connection
    "stanchion": new GridSize(0.3, 0.3),        // anywhere, replace the connectors with itself, fill the full height of connection
    "stanchion_with_arc": new GridSize(1, 2),   // anywhere, replace the connectors with itself, fill the full height of connection
    "stanchion_with_arc_150cm": new GridSize(1, 3),   // anywhere, replace the connectors with itself, fill the full height of connection
    "stanchion_with_arc_200cm": new GridSize(1, 4),   // anywhere, replace the connectors with itself, fill the full height of connection
    "stanchion_inside": new GridSize(1, 2),     // anywhere, replace the connectors with itself, fill the full height of connection

    "winch": new GridSize(0.3, 0.3),   // inside only, remove pin, fill the full height of connection  
  },

  /**
   * If element has a level of 0 it will level itself on the connection (fill all available spacers)
   */
  ELEMENT_CONNECTIONS: {
    "single_element": [
      {
        position: new GridPoint(0, 0),
        level: 3
      },
      {
        position: new GridPoint(1, 0),
        level: 2,
      },
      {
        position: new GridPoint(0, 1),
        level: 4
      },
      {
        position: new GridPoint(1, 1),
        level: 1
      },
    ],
    "double_element": [
      {
        position: new GridPoint(0, 0),
        level: 3,
      },
      {
        position: new GridPoint(1, 0),
        level: 2,
      },
      {
        position: new GridPoint(0, 1),
        level: 4,
        middleConnector: true
      },
      {
        position: new GridPoint(1, 1),
        level: 1,
        middleConnector: true
      },
      {
        position: new GridPoint(0, 2),
        level: 4,
      },
      {
        position: new GridPoint(1, 2),
        level: 1,
      },
    ],
    /**
     * Ladder is a special case becouse 2 connecotr come with pins attached and 2 come with sidescrews attached
     * 2 with pins remove the pin placed there but keep the spacer amount
     * 2 with sidescrews must have a "full height" rule applied
     */
    "ladder": { 
      0: [
        {
          position: new GridPoint(0, 0),
          level: 99,
        },
        {
          position: new GridPoint(1, 0),
          level: 0,
        },
        {
          position: new GridPoint(0, 1),
          level: 99,
        },
        {
          position: new GridPoint(1, 1),
          level: 0,
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 99,
        },
        {
          position: new GridPoint(1, 0),
          level: 99,
        },
        {
          position: new GridPoint(0, 1),
          level: 0,
        },
        {
          position: new GridPoint(1, 1),
          level: 0,
        }
      ],
      2: [
        {
          position: new GridPoint(0, 0),
          level: 0,
        },
        {
          position: new GridPoint(1, 0),
          level: 99,
        },
        {
          position: new GridPoint(0, 1),
          level: 0,
        },
        {
          position: new GridPoint(1, 1),
          level: 99,
        }
      ],
      3: [
        {
          position: new GridPoint(0, 0),
          level: 0,
        },
        {
          position: new GridPoint(1, 0),
          level: 0,
        },
        {
          position: new GridPoint(0, 1),
          level: 99,
        },
        {
          position: new GridPoint(1, 1),
          level: 99,
        }
      ],
    },
    "side_bumper": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
    },
    "side_bumper_3": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        }
      ],
    },
    "side_bumper_4": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
        {
          position: new GridPoint(0, 3),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        },
        {
          position: new GridPoint(3, 1),
          level: 0
        },
      ],
    },
    "side_bumper_5": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
        {
          position: new GridPoint(0, 3),
          level: 0
        },
        {
          position: new GridPoint(0, 4),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(4, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        },
        {
          position: new GridPoint(1, 4),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        },
        {
          position: new GridPoint(3, 1),
          level: 0
        },
        {
          position: new GridPoint(4, 1),
          level: 0
        },
      ],
    },
    "side_bumper_6": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
        {
          position: new GridPoint(0, 3),
          level: 0
        },
        {
          position: new GridPoint(0, 4),
          level: 0
        },
        {
          position: new GridPoint(0, 5),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0,
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(4, 0),
          level: 0
        },
        {
          position: new GridPoint(5, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        },
        {
          position: new GridPoint(1, 4),
          level: 0
        },
        {
          position: new GridPoint(1, 5),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        },
        {
          position: new GridPoint(3, 1),
          level: 0
        },
        {
          position: new GridPoint(4, 1),
          level: 0
        },
        {
          position: new GridPoint(5, 1),
          level: 0
        },
      ],
    },
    "boat_cleat": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "boat_fender_with_nut": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "screw_joint_unit": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "screw_joint_unit_special": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],    
    "boat_mounting": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "cross_bollard_edge": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "cross_bollard_pin": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "connecting_pin_with_steel": [
      {
        position: new GridPoint(0, 0),
        level: 6 // TODO How to handle this case as its placed on top of the pin ?
      },
    ],
    'guiding_sleeve_umbrellas': [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "flag_pole": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    // This is outside only element so rotation matters when placing it on the corners cs flag pole where it dosent matter at all
    "pile_cage_max_50_outer": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
    },
    "pile_cage_for_tubes_outer": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
    },
    "pile_cage_gliding_system_outer": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
    },
    "pile_cage_max_500_outer": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 1),
          level: 0
        },
        {
          position: new GridPoint(3, 2),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 3),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        },
        {
          position: new GridPoint(2, 3),
          level: 0
        }
      ],
    },
    "pile_cage_max_230_outer": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
    },
    "pile_cage_max_300_outer": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
    },
    "pile_cage_max_300_outer_3": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        },
      ],
    },
    "pile_cage_310_inner": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
      {
        position: new GridPoint(1, 0),
        level: 0
      },
      {
        position: new GridPoint(0, 1),
        level: 0
      },
      {
        position: new GridPoint(1, 1),
        level: 0
      },
    ],
    "pile_cage_standard_inner": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
      {
        position: new GridPoint(1, 0),
        level: 0
      },
      {
        position: new GridPoint(0, 1),
        level: 0
      },
      {
        position: new GridPoint(1, 1),
        level: 0
      },
    ],
    "pile_cage_600_inner":[
      {
        position: new GridPoint(0, 0),
        level: 0
      },
      {
        position: new GridPoint(1, 0),
        level: 0
      },
      {
        position: new GridPoint(2, 0),
        level: 0
      },
      {
        position: new GridPoint(0, 1),
        level: 0
      },
      {
        position: new GridPoint(2, 1),
        level: 0
      },
      {
        position: new GridPoint(0, 2),
        level: 0
      },
      {
        position: new GridPoint(1, 2),
        level: 0
      },
      {
        position: new GridPoint(2, 2),
        level: 0
      },   
    ],
    "mooring_device_0": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "mooring_device_2": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        }
      ],
    },
    "mooring_device_3": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        }
      ],
    },
    "stanchion": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "stanchion_with_brace": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
    },
    "stanchion_with_arc": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        }
      ],
    },
    "stanchion_with_arc_150cm": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 3),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(3, 1),
          level: 0
        }
      ],
    },
    "stanchion_with_arc_200cm": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 4),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(4, 0),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 4),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(4, 1),
          level: 0
        }
      ],
    },
    "stanchion_inside": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        }
      ],
    },
    "stanchion_corner": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        }
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        }
      ],
      3: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        }
      ],
    },
    "winch": [
      {
        position: new GridPoint(0, 0),
        level: 0
      },
    ],
    "bathing_slide": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 3),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        },
        {
          position: new GridPoint(0, 6),
          level: 0
        },
        {
          position: new GridPoint(1, 6),
          level: 0
        }
      ],
      1: [
        {
          position: new GridPoint(8, 0),
          level: 0
        },
        {
          position: new GridPoint(8, 1),
          level: 0
        },
        {
          position: new GridPoint(5, 0),
          level: 0
        },
        {
          position: new GridPoint(5, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(0, 8),
          level: 0
        },
        {
          position: new GridPoint(1, 8),
          level: 0
        },
        {
          position: new GridPoint(0, 5),
          level: 0
        },
        {
          position: new GridPoint(1, 5),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 1),
          level: 0
        },
        {
          position: new GridPoint(6, 0),
          level: 0
        },
        {
          position: new GridPoint(6, 1),
          level: 0
        }
      ],
    },
    /**
     * This is outside only element so rotation matters when placing it on the corners
     * We always use the first point in corner bumpers to check for neighbours if the point 
     * is a corner for reference where element is stored check image in public folder
     */
    "side_bumper_corner_outer_0": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
    },
    "side_bumper_corner_outer": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(-1, 0),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, -1),
          level: 0
        },
        {
          position: new GridPoint(-1, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(1, -1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
    },
    "side_bumper_corner_outer_3": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
        {
          position: new GridPoint(-1, 0),
          level: 0
        },
        {
          position: new GridPoint(-2, 0),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, -1),
          level: 0
        },
        {
          position: new GridPoint(0, -2),
          level: 0
        },
        {
          position: new GridPoint(-1, 0),
          level: 0
        },
        {
          position: new GridPoint(-2, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(1, -1),
          level: 0
        },
        {
          position: new GridPoint(1, -2),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
      ],
    },
    "side_bumper_corner_outer_4": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
        {
          position: new GridPoint(0, 3),
          level: 0
        },
        {
          position: new GridPoint(-1, 0),
          level: 0
        },
        {
          position: new GridPoint(-2, 0),
          level: 0
        },
        {
          position: new GridPoint(-3, 0),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, -1),
          level: 0
        },
        {
          position: new GridPoint(0, -2),
          level: 0
        },
        {
          position: new GridPoint(0, -3),
          level: 0
        },
        {
          position: new GridPoint(-1, 0),
          level: 0
        },
        {
          position: new GridPoint(-2, 0),
          level: 0
        },
        {
          position: new GridPoint(-3, 0),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(4, 0),
          level: 0
        },
        {
          position: new GridPoint(1, -1),
          level: 0
        },
        {
          position: new GridPoint(1, -2),
          level: 0
        },
        {
          position: new GridPoint(1, -3),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(4, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        },
      ],
    },

    /**
     * This is inside only element so rotation matters when placing it on the corners
     * We always use the first point in corner bumpers to check for neighbours if the point 
     * is a corner for reference where element is stored check image in public folder
     */
    //"side_bumper_corner_inner_0"
    "side_bumper_corner_inner": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
      ],
    },
    "side_bumper_corner_inner_3": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(-1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(-1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, -1),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, -1),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(2, 1),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
      ],
    },
    "side_bumper_corner_inner_4": {
      0: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(-1, 0),
          level: 0
        },
        {
          position: new GridPoint(-2, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        },
      ],
      1: [
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(-1, 1),
          level: 0
        },
        {
          position: new GridPoint(-2, 1),
          level: 0
        },
        {
          position: new GridPoint(1, -1),
          level: 0
        },
        {
          position: new GridPoint(1, -2),
          level: 0
        },
      ],
      2: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(0, -1),
          level: 0
        },
        {
          position: new GridPoint(0, -2),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 1),
          level: 0
        },
        {
          position: new GridPoint(1, 2),
          level: 0
        },
        {
          position: new GridPoint(1, 3),
          level: 0
        },
      ],
      3: [
        {
          position: new GridPoint(0, 0),
          level: 0
        },
        {
          position: new GridPoint(1, 0),
          level: 0
        },
        {
          position: new GridPoint(2, 0),
          level: 0
        },
        {
          position: new GridPoint(3, 0),
          level: 0
        },
        {
          position: new GridPoint(0, 1),
          level: 0
        },
        {
          position: new GridPoint(0, 2),
          level: 0
        },
        {
          position: new GridPoint(0, 3),
          level: 0
        },
      ],
    },
  }
};
