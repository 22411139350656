const globals = {
    api: {
        endpoint: process.env.VUE_APP_BACKEND_URL,
        general: {
            app: {
                data: process.env.VUE_APP_BACKEND_URL + 'general-application-data',
                downloadProjectFile: 'download-project-file/',
                validation: {
                    projectData: process.env.VUE_APP_BACKEND_URL + 'public-projects/validate-project-data',
                    customerData: process.env.VUE_APP_BACKEND_URL + 'public-projects/validate-customer-data',
                },
                submitProject : process.env.VUE_APP_BACKEND_URL + 'public-projects/create',
                updateProject : process.env.VUE_APP_BACKEND_URL + 'public-projects/update',
                loadProject : process.env.VUE_APP_BACKEND_URL + 'public-projects/get-with-reference',
                uploadFile : process.env.VUE_APP_BACKEND_URL + 'upload-file'
            },
        },
    }
}


export default globals;