import constants from "./constants";

export default class Helpers {
  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is an accessory
   */
  isAccessory(rule) {
    if (
      this.isSideBumper(rule) ||
      this.isSideBumperCornerOuter(rule) ||
      this.isSideBumperCornerInner(rule) ||
      this.isLadder(rule) ||
      this.isOuterScrewJoint(rule) ||
      this.isInnerScrewJoint(rule) ||
      this.isSpecialScrewJoint(rule) ||
      this.isConnectingPinWithSteel(rule) ||
      this.isPileCageOuter(rule) ||
      this.isPileCageInner(rule) ||
      this.isMooringDevice(rule) ||
      this.isFence(rule) ||
      this.isWinch(rule) ||
      this.isBathingSlide(rule)
    ) {
      return true
    }

    return false
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered basic
   */
  isBasicElement(rule) {
    return [
      constants.RULESET_SINGLE_ELEMENT,
      constants.RULESET_DOUBLE_ELEMENT,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is default connector
   */
  isDefaultConnector(rule) {
    return [
      constants.RULESET_CONNECTING_PIN_SHORT,
      constants.RULESET_SIDE_SCREW,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is connector
   */
  isConnector(rule) {
    return [
      constants.RULESET_CONNECTING_PIN_SHORT,
      constants.RULESET_SIDE_SCREW,
      constants.RULESET_CONNECTING_PIN_LONG,
      constants.RULESET_SIDE_SCREW_WITH_NUT_LONG,
      constants.RULESET_SIDE_SCREW_290,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is default connecting pin (connecting pin short)
   */
  isDefaultConnectingPin(rule) {
    return [
      constants.RULESET_CONNECTING_PIN_SHORT,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is default side screw
   */
  isDefaultSideScrew(rule) {
    return [
      constants.RULESET_SIDE_SCREW,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered side bumper
   */
  isSideBumper(rule) {
    return [
      constants.RULESET_SIDE_BUMPER,
      constants.RULESET_SIDE_BUMPER_3,
      constants.RULESET_SIDE_BUMPER_4,
      constants.RULESET_SIDE_BUMPER_5,
      constants.RULESET_SIDE_BUMPER_6,
      constants.RULESET_SIDE_BUMPER_7,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered side bumper outer corner only
   */
  isSideBumperCornerOuter(rule) {
    return [
      constants.RULESET_SIDE_BUMPER_CORNER_OUTER_0,
      constants.RULESET_SIDE_BUMPER_CORNER_OUTER,
      constants.RULESET_SIDE_BUMPER_CORNER_OUTER_3,
      constants.RULESET_SIDE_BUMPER_CORNER_OUTER_4,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered side bumper inner corner only
   */
   isSideBumperCornerInner(rule) {
    return [
      constants.RULESET_SIDE_BUMPER_CORNER_INNER_0,
      constants.RULESET_SIDE_BUMPER_CORNER_INNER,
      constants.RULESET_SIDE_BUMPER_CORNER_INNER_3,
      constants.RULESET_SIDE_BUMPER_CORNER_INNER_4,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered a ladder
   */
  isLadder(rule) {
    return [
      constants.RULESET_LADDER,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered a bathing slide
   */
  isBathingSlide(rule) {
    return [
      constants.RULESET_BATHING_SLIDE,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered a outer screw joint
   */
  isOuterScrewJoint(rule) {
    return [
      constants.RULESET_BOAT_CLEAT,
      constants.RULESET_SCREW_JOINT_UNIT,
      constants.RULESET_CROSS_BOLLARD_EDGE,
      constants.RULESET_BOAT_FENDER_WITH_NUT,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered a inner screw joint
   */
  isInnerScrewJoint(rule) {
    return [
      constants.RULESET_BOAT_MOUNTING,
      constants.RULESET_CROSS_BOLLARD_PIN,
      constants.RULESET_GUIDING_SLEEVE_UMBRELLAS,
      constants.RULESET_FLAG_POLE,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered a special screw joint
   */
  isSpecialScrewJoint(rule) {
    return [
      constants.RULESET_SCREW_JOINT_UNIT_SPECIAL,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is considered a coonecting pin with steel
   */
  isConnectingPinWithSteel(rule) {
    return [
      constants.RULESET_CONNECTING_PIN_WITH_STEEL,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is pile cage (outer)
   */
  isPileCageOuter(rule) {
    return [
      constants.RULESET_PILE_CAGE_FOR_TUBES_OUTER,
      constants.RULESET_PILE_CAGE_GLIDING_SYSTEM_OUTER,
      constants.RULESET_PILE_CAGE_MAX_50_OUTER,
      constants.RULESET_PILE_CAGE_MAX_500_OUTER,
      constants.RULESET_PILE_CAGE_MAX_230_OUTER,
      constants.RULESET_PILE_CAGE_MAX_300_OUTER,
      constants.RULESET_PILE_CAGE_MAX_300_OUTER_3,
    ].includes(rule)
  }

  
  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is pile cage (inner)
   */
  isPileCageInner(rule) {
    return [
      constants.RULESET_PILE_CAGE_310_INNER,
      constants.RULESET_PILE_CAGE_STANDARD_INNER,
      constants.RULESET_PILE_CAGE_600_INNER,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element mooring device
   */
  isMooringDevice(rule) {
    return [
      constants.MOORING_DEVICE_0,
      constants.MOORING_DEVICE_2,
      constants.MOORING_DEVICE_3,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is a fence
   */
  isFence(rule) {
    return [
      constants.STANCHION_CORNER,
      constants.STANCHION_WITH_BRACE,
      constants.STANCHION,
      constants.STANCHION_WITH_ARC,
      constants.STANCHION_INSIDE,
      constants.STANCHION_WITH_ARC_150,
      constants.STANCHION_WITH_ARC_200,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is a corner fence
   */
  isCornerFence(rule) {
    return [
      constants.STANCHION_CORNER,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is a winch
   */
  isWinch(rule) {
    return [
      constants.WINCH,
    ].includes(rule)
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is overlaying
   */
  isOverlayingElement(rule) {
    if (this.isFence(rule) || this.isConnectingPinWithSteel(rule) || this.isLadder(rule) || this.isBathingSlide(rule)) {
      return true
    }

    return false
  }

  /**
   * 
   * @param {string} rule 
   * @returns bool
   * 
   * Return true if element is connector acting like element
   */
  isElementActingLikeConnector(rule) {
    if (this.isOuterScrewJoint(rule) || this.isInnerScrewJoint(rule) || this.isWinch(rule) || this.isSpecialScrewJoint(rule)) {
      return true
    }

    return false
  }

  /**
   * 
   * @param {*} pixiElement 
   * @param {int} rotation 
   * @param {int} boxSize 
   * @param {string} rule 
   * @param {int} width 
   * @param {int} height 
   * 
   * General helper to help draw / redraw current pixi element
   */
  offsetPixiElement(pixiElement, rotation, boxSize, rule, width = 1, height = 1) {
    if (this.isBasicElement(rule)) {
      pixiElement.position.x -= 8;
      pixiElement.position.y -= 8;
      pixiElement.angle = 0;
      pixiElement.anchor.set(0, 0);
      pixiElement.scale.set(1.2, 1.2);
    }

    if (this.isSideBumper(rule)) {
      this.handleRotationSideBumper(pixiElement, rotation, boxSize);
      pixiElement.scale.set(1, 1);
    }

    if (this.isMooringDevice(rule)) {
      this.handleRotationMorringDevice(pixiElement, rotation, boxSize, rule)
      pixiElement.scale.set(1, 1);
    }

    if (this.isSideBumperCornerOuter(rule) || this.isSideBumperCornerInner(rule)) {
      this.handleRotationSideBumperCorner(pixiElement, rotation, boxSize, rule);
      pixiElement.scale.set(1, 1);
    }

    if (this.isLadder(rule)) {
      pixiElement.width = width * boxSize;
      pixiElement.height = height * boxSize;
      pixiElement.angle = rotation * 90;
      pixiElement.position.x += 37; // TODO maginc numbers 
      pixiElement.position.y += 37; // TODO maginc numbers 
      pixiElement.anchor.set(0.5, 0.5);
      pixiElement.scale.set(1.2, 1.2);
    }

    if (this.isInnerScrewJoint(rule) || this.isConnectingPinWithSteel(rule) || this.isWinch(rule) || this.isOuterScrewJoint(rule) || this.isConnector(rule) || this.isSpecialScrewJoint(rule)) {
      pixiElement.scale.set(1, 1);
      pixiElement.anchor.set(0.5, 0.5);
    }

    if (this.isFence(rule)) {
      this.handleRotationFence(pixiElement, rotation, boxSize, rule)
      pixiElement.scale.set(1, 1);
    }

    if (this.isPileCageOuter(rule) || this.isPileCageInner(rule)) {
      this.handleRotationPileCage(pixiElement, rotation, boxSize, rule)
      if(this.isPileCageOuter(rule)) {
        pixiElement.scale.set(1, 1);
      } else {
        pixiElement.scale.set(1.1, 1.1);
      }
    }

    if (this.isBathingSlide(rule)) {
      this.handleRotationBathingSlide(pixiElement, rotation, boxSize, rule)
      pixiElement.scale.set(1, 1);
    }
  }

  handleRotationBathingSlide(pixiElement, rotation, boxSize) {
    this.handleRotation(pixiElement, rotation)

    switch (rotation) {
      case 0: { // left
        pixiElement.position.x -= 13;
        pixiElement.position.y -= 10;
        break;
      }
      case 1: { // top
        pixiElement.position.x += 10;
        pixiElement.position.y += boxSize + 13;
        break;
      }
      case 2: { // right
        pixiElement.position.x += boxSize + 13;
        pixiElement.position.y += 10;
        break;
      }
      case 3: { // down
        pixiElement.position.x -= 10;
        pixiElement.position.y -= 13;
        break;
      }
    }
  }

  handleRotationPileCage(pixiElement, rotation, boxSize, rule) {
    this.handleRotation(pixiElement, rotation)

    if (rule == constants.RULESET_PILE_CAGE_FOR_TUBES_OUTER || rule == constants.RULESET_PILE_CAGE_GLIDING_SYSTEM_OUTER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 7;
          pixiElement.position.y -= 7;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 7;
          pixiElement.position.y += boxSize - 7;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize + 7;
          pixiElement.position.y -= 7;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 7;
          pixiElement.position.y += 7;
          break;
        }
      }
    } 
    
    if(rule == constants.RULESET_PILE_CAGE_MAX_50_OUTER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 25;
          pixiElement.position.y -= 35;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 35;
          pixiElement.position.y += boxSize - 25;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize + 25;
          pixiElement.position.y -= 35;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 35;
          pixiElement.position.y += 25;
          break;
        }
      }
    }
    
    if(rule == constants.RULESET_PILE_CAGE_MAX_500_OUTER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 5;
          pixiElement.position.y -= 9;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 9;
          pixiElement.position.y += (3 * boxSize) - 5;
          break;
        }
        case 2: { // right
          pixiElement.position.x += (3 * boxSize) + 5;
          pixiElement.position.y -= 9;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 9;
          pixiElement.position.y += 5;
          break;
        }
      }
    }

    if(rule == constants.RULESET_PILE_CAGE_MAX_300_OUTER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 5;
          pixiElement.position.y -= 25;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= boxSize - 25;
          pixiElement.position.y += boxSize - 5;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize + 5;
          pixiElement.position.y -= boxSize - 25;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 25;
          pixiElement.position.y += 5;
          break;
        }
      }
    }

    if(rule == constants.RULESET_PILE_CAGE_MAX_230_OUTER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 5;
          pixiElement.position.y -= 10;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 5;
          pixiElement.position.y += boxSize + 10;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize+ 5;
          pixiElement.position.y -= 5;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 10;
          pixiElement.position.y -= 10;
          break;
        }
      }
    }

    if(rule == constants.RULESET_PILE_CAGE_MAX_300_OUTER_3) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 5;
          pixiElement.position.y -= 5;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 5;
          pixiElement.position.y += boxSize - 5;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize + 5;
          pixiElement.position.y -= 5;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 5;
          pixiElement.position.y += 5;
          break;
        }
      }
    }

    if (rule == constants.RULESET_PILE_CAGE_310_INNER || rule == constants.RULESET_PILE_CAGE_STANDARD_INNER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 5;
          pixiElement.position.y -= 5;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 5;
          pixiElement.position.y += boxSize + 5;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize + 5;
          pixiElement.position.y -= 5;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 5;
          pixiElement.position.y -= 5;
          break;
        }
      }
    }
    
    if (rule == constants.RULESET_PILE_CAGE_600_INNER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 7;
          pixiElement.position.y -= 7;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 7;
          pixiElement.position.y += (2 * boxSize) + 7;
          break;
        }
        case 2: { // right
          pixiElement.position.x += (2 * boxSize) + 7;
          pixiElement.position.y -= 7;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 7;
          pixiElement.position.y -= 7;
          break;
        }
      }
    } 
  }

  handleRotationFence(pixiElement, rotation, boxSize, rule) {
    this.handleRotation(pixiElement, rotation)

    if (rule == constants.STANCHION_WITH_BRACE || rule == constants.STANCHION_INSIDE || rule == constants.STANCHION_WITH_ARC_150 || rule == constants.STANCHION_WITH_ARC_200 || rule == constants.STANCHION_WITH_ARC) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 7;
          pixiElement.position.y -= 7;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 7;
          pixiElement.position.y += boxSize - 7;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize + 7;
          pixiElement.position.y -= 7;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 7;
          pixiElement.position.y += 7;
          break;
        }
      }
    }

    if (rule == constants.STANCHION) {
      // always keep single stanchion rotation as 0  
      // it is like pin so it does not make sense to rotate it  
      this.handleRotationAndCenter(pixiElement, 0);
    }

    if (rule == constants.STANCHION_CORNER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 10;
          pixiElement.position.y -= 10;
          break;
        }
        case 1: { // top
          pixiElement.position.x += -10;
          pixiElement.position.y += boxSize + 10;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize + 10;
          pixiElement.position.y -= 10;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 10;
          pixiElement.position.y -= 10;
          break;
        }
      }
    }

  }

  handleRotationSideBumperCorner(pixiElement, rotation, boxSize, rule) {
    this.handleRotation(pixiElement, rotation)

    // Depending on the lenght of element the offset gets bigger so user click on the same spot hence triggering the same logic
    if(rule == constants.RULESET_SIDE_BUMPER_CORNER_OUTER_0) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= boxSize - 25;
          pixiElement.position.y -= 25;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= boxSize - 25;
          pixiElement.position.y += 25;
          break;
        }
        case 2: { // right
          pixiElement.position.x += 2 * boxSize - 25;
          pixiElement.position.y -= boxSize - 25;
          break;
        }
        case 3: { // down
          pixiElement.position.x += boxSize - 25;
          pixiElement.position.y -= 25;
          break;
        }
      }
    }

    if(rule == constants.RULESET_SIDE_BUMPER_CORNER_OUTER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 2 * boxSize - 25;
          pixiElement.position.y -= 25;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 2 * boxSize - 25;
          pixiElement.position.y += 25;
          break;
        }
        case 2: { // right
          pixiElement.position.x += 3 * boxSize - 25;
          pixiElement.position.y -= 2 * boxSize - 25;
          break;
        }
        case 3: { // down
          pixiElement.position.x += boxSize - 25;
          pixiElement.position.y -= 25;
          break;
        }
      }
    }
    
    if(rule == constants.RULESET_SIDE_BUMPER_CORNER_OUTER_3) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 2 * boxSize;
          pixiElement.position.y -= 25;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 2 * boxSize;
          pixiElement.position.y += 25;
          break;
        }
        case 2: { // right
          pixiElement.position.x += 3 * boxSize;
          pixiElement.position.y -= 2 * boxSize;
          break;
        }
        case 3: { // down
          pixiElement.position.x += boxSize - 25;
          pixiElement.position.y -= 25;
          break;
        }
      }
    }
    
    if(rule == constants.RULESET_SIDE_BUMPER_CORNER_OUTER_4) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 3 * boxSize;
          pixiElement.position.y -= 25;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 3 * boxSize;
          pixiElement.position.y += 25;
          break;
        }
        case 2: { // right
          pixiElement.position.x += 4 * boxSize;
          pixiElement.position.y -= 3 * boxSize;
          break;
        }
        case 3: { // down
          pixiElement.position.x += boxSize - 25;
          pixiElement.position.y -= 25;
          break;
        }
      }
    }

    if(rule == constants.RULESET_SIDE_BUMPER_CORNER_INNER) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= boxSize;
          pixiElement.position.y += 0;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= boxSize;
          pixiElement.position.y += boxSize;
          break;
        }
        case 2: { // right
          pixiElement.position.x += 2 * boxSize;
          pixiElement.position.y -= boxSize;
          break;
        }
        case 3: { // down
          pixiElement.position.x += 0;
          pixiElement.position.y -= 0;
          break;
        }
      }
    }

    if(rule == constants.RULESET_SIDE_BUMPER_CORNER_INNER_3) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= boxSize + 25;
          pixiElement.position.y += 0;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= boxSize + 25;
          pixiElement.position.y += boxSize;
          break;
        }
        case 2: { // right
          pixiElement.position.x += 2 * boxSize + 25;
          pixiElement.position.y -= boxSize + 25;
          break;
        }
        case 3: { // down
          pixiElement.position.x += 0;
          pixiElement.position.y -= 0;
          break;
        }
      }
    }

    if(rule == constants.RULESET_SIDE_BUMPER_CORNER_INNER_4) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 2 * boxSize + 25;
          pixiElement.position.y += 0;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 2 * boxSize + 25;
          pixiElement.position.y += boxSize;
          break;
        }
        case 2: { // right
          pixiElement.position.x += 3 * boxSize + 25;
          pixiElement.position.y -= 2 * boxSize + 25;
          break;
        }
        case 3: { // down
          pixiElement.position.x += 0;
          pixiElement.position.y -= 0;
          break;
        }
      }
    }
  }

  handleRotationSideBumper(pixiElement, rotation, boxSize) {
    this.handleRotation(pixiElement, rotation)
    switch (rotation) {
      case 0: { // left
        pixiElement.position.x -= 8;
        pixiElement.position.y -= 43;
        break;
      }
      case 1: { // top
        pixiElement.position.x -= 43;
        pixiElement.position.y += boxSize - 8;
        break;
      }
      case 2: { // right
        pixiElement.position.x += boxSize + 8;
        pixiElement.position.y -= 43;
        break;
      }
      case 3: { // down
        pixiElement.position.x -= 43;
        pixiElement.position.y += 8;
        break;
      }
    }
  }

  handleRotationMorringDevice(pixiElement, rotation, boxSize, rule) {
    this.handleRotation(pixiElement, rotation)

    if(rule == constants.MOORING_DEVICE_0) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= (boxSize / 2);
          pixiElement.position.y -= (boxSize / 2);
          break;
        }
        case 1: { // top
          pixiElement.position.x -= (boxSize / 2);
          pixiElement.position.y += (boxSize / 2);
          break;
        }
        case 2: { // right
          pixiElement.position.x += (boxSize / 2);
          pixiElement.position.y -= (boxSize / 2);
          break;
        }
        case 3: { // down
          pixiElement.position.x -= (boxSize / 2);
          pixiElement.position.y -= (boxSize / 2);
          break;
        }
      }
      
    }

    if(rule == constants.MOORING_DEVICE_2 || rule == constants.MOORING_DEVICE_3) {
      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 17;
          pixiElement.position.y -= 14;
          break;
        }
        case 1: { // top
          pixiElement.position.x -= 14;
          pixiElement.position.y += boxSize - 17;
          break;
        }
        case 2: { // right
          pixiElement.position.x += 17;
          pixiElement.position.y -= 14;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 14;
          pixiElement.position.y -= boxSize -17;
          break;
        }
      }
    }
  }
      
  handleRotation(pixiElement, rotation) {
    pixiElement.angle = rotation * 90;
    switch (rotation) {
      case 0: { // left
        pixiElement.anchor.set(0, 0);
        break;
      }
      case 1: { // top
        pixiElement.anchor.set(1, 1);
        break;
      }
      case 2: { // right
        pixiElement.anchor.set(0, 1);
        break;
      }
      case 3: { // down
        pixiElement.anchor.set(1, 0);
        break;
      }
    }
  }

  handleRotationAndCenter(pixiElement, rotation) {
    pixiElement.angle = rotation * 90;
    switch (rotation) {
      case 0: { // left
        pixiElement.anchor.set(0.5, 0.5);
        break;
      }
      case 1: { // top
        pixiElement.anchor.set(1, 1);
        break;
      }
      case 2: { // right
        pixiElement.anchor.set(0, 1);
        break;
      }
      case 3: { // down
        pixiElement.anchor.set(1, 0);
        break;
      }
    }
  }
}

