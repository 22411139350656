import constants from "@/components/configurator/Editor/lib/constants.js";

const state = () => ({
    nLines: 250, 
    boxSize: 75,
    level: 2,
    currentItemType: null,
    primaryItemsDialog: false,
    accessoriesDialog: false,
    primaryItems: {
        item: null,
        variation: null,
    },
    accersories: {
        item: null,
        variation: null,
    },
})

const getters = {
    getNLines(state) {
        return state.nLines
    },
    getBoxSize(state) {
        return state.boxSize
    },
    getLevel(state) {
        return state.level
    },
    getCurrentItemType(state) {
        return state.currentItemType
    },
    getPrimaryItemsDialog(state) {
        return state.primaryItemsDialog
    },
    getAccessoriesDialog(state) {
        return state.accessoriesDialog
    },
    getPrimaryItem(state) {
        return state.primaryItems.item
    },
    getPrimaryItemVariation(state) {
        return state.primaryItems.variation
    },
    getAccessory(state) {
        return state.accersories.item
    },
    getAccessoryVariation(state) {
        return state.accersories.variation
    },
    getCurrentItem(state) {
        if (state.currentItemType == "primary") {
            return {
                item: state.primaryItems.item,
                variation: state.primaryItems.variation,
            }
        } else if (state.currentItemType == "accessory") {
            return {
                item: state.accersories.item,
                variation: state.accersories.variation,
            }
        }
        return {};
    }
};

//const actions = {};

const mutations = {
    setLevel(state, newLevel) {
        state.level = newLevel
    },
    setDefaultItemAndVariation(state, payload) {
        state.currentItemType = "primary";
        // TODO Augment element until we receive this info from CRM
        payload.item.size = constants.ELEMENT_SIZES[payload.item.rule];
        payload.item.availableConnections = constants.ELEMENT_CONNECTIONS[payload.item.rule];
        state.primaryItems.item = payload.item
        state.primaryItems.variation = payload.variation
    },
    setPrimaryItemsDialog(state, newPrimaryItemsDialog) {
        state.primaryItemsDialog = newPrimaryItemsDialog
    },
    setAccessoriesDialog(state, newAccessoriesDialog) {
        state.accessoriesDialog = newAccessoriesDialog
    },
    setPrimaryItem(state, newItem) {
        state.currentItemType = "primary";
        // TODO Augment element until we receive this info from CRM
        newItem.size = constants.ELEMENT_SIZES[newItem.rule];
        newItem.availableConnections = constants.ELEMENT_CONNECTIONS[newItem.rule];
        state.primaryItems.item = newItem
    },
    setPrimaryItemVariation(state, newVariation) {
        state.primaryItems.variation = newVariation
    },
    setAccessory(state, newItem) {
        state.currentItemType = "accessory";
        // TODO Augment element until we receive this info from CRM
        newItem.size = constants.ELEMENT_SIZES[newItem.rule];
        newItem.availableConnections = constants.ELEMENT_CONNECTIONS[newItem.rule];
        state.accersories.item = newItem
    },
    setAccessoryVariation(state, newVariation) {
        state.accersories.variation = newVariation
    },
    
};

//const methods = {};


export default {
    namespaced: true,
    state,
    getters,
    //actions,
    mutations,
};