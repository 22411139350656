
<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item v-if="mode == 'edit'">
            <v-list-item-title>
              Current project: {{ projectName }}<br>
              Customer email: {{ projectCustomerEmail }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="text-center" v-if="userData != null">
            <v-list-item-title>
              <v-card
                elevation="15">

                <u>Logged in as:</u>
                <br>
                {{ userData.name }} {{ userData.lastname }}<br>
                {{ userData.email }}
              </v-card>
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="text-center">
            <v-list-item-title>
                <v-btn style="width:100%" :href="crmUrl" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>  CRM Portal
                </v-btn>
              </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <div class="logo-container">
        <a href="/">
        <img src='./assets/logo_jetfloat.svg'>
        </a>
      </div>
      <v-spacer></v-spacer>
      <v-divider
        class="mx-4 hidden-xs-only"
        vertical
      ></v-divider>
      <v-row class="nav-row-item hidden-xs-only">
        <v-col>
          <v-btn :href="crmUrl" target="_blank"><v-icon small>mdi-open-in-new</v-icon>  CRM Portal</v-btn>
        </v-col>
      </v-row>
      <v-divider
        class="mx-4"
        vertical
        v-if="mode == 'edit'"
      ></v-divider>
      <v-row class="nav-row-item text-line" v-if="mode == 'edit'">
        <v-col cols=8>
          Current project: {{ projectName }}<br>
          Customer email: {{ projectCustomerEmail }}
        </v-col> 
        <v-col cols=4> 
          <v-btn 
            style="float:right;" 
            middle 
            v-on:click="closeProject">
              Close
          </v-btn>
        </v-col>
      </v-row>
      <v-divider
        class="mx-4 hidden-xs-only"
        vertical
        v-if="userData != null"
      ></v-divider>
      <v-row class="nav-row-item hidden-xs-only" v-if="userData != null">
        <v-col>
          <u>Logged in as:</u>
          <br>
          {{ userData.name }} {{ userData.lastname }}<br>
          {{ userData.email }}
        </v-col>
      </v-row>
      <v-app-bar-nav-icon class="hidden-sm-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'JetfloatConfigurator',

    data: () => ({ 
        drawer: null,
        group: null,
        crmUrl: process.env.VUE_APP_CRM_URL
      }
    ),

    computed: {
      ...mapGetters({
        projectName: 'projectData/getProjectName',
        projectCustomerEmail: 'projectData/getCustomerEmail',
        mode: 'projectData/getMode',
        userData: 'api/getUserData'
      })
    },

    methods: {
      closeProject() {
        window.location.href = '/';
      }
    },

    watch: {
      group () {
        this.drawer = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .logo-container {
    width: 160px;
    height: auto;
  }
  .nav-row-item {
    font-size:0.8em; 
    flex-grow: revert;
  }
  .text-line {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>