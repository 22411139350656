import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router';
import store from './plugins/store/vuex';
import globals from './plugins/globals';
import axios from 'axios'

import './assets/scss/main.scss';
import i18n from './i18n'

Vue.config.productionTip = false
Vue.prototype.$globals = globals
Vue.prototype.$http = axios;

store.dispatch("api/getApplicationData").then(function () { //wait for api call to finish before loading app

    new Vue({
      vuetify,
      router,
      store,
      axios,
      i18n,
      render: h => h(App)
    }).$mount('#app')
});
