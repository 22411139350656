import axios from 'axios'
import router from '../../router';
import globals from '../../globals'
import constants from "../../../components/configurator/Editor/lib/constants";
import Helpers from "@/components/configurator/Editor/lib/Helpers.js"

let helpers = new Helpers();

const state = () => ({
    userData: null,
    applicationData: null,
    inventory: [],
})

const getters = {
    getUserData(state) {
        return state.userData
    },
    getApplicationData(state) {
        return state.applicationData;
    },
    getPrimaryItems(state) {
        let primaryProducts = [];
        
        //when api call is in progress return empty array
        if (!state.applicationData) return [];

        state.applicationData.products.forEach(product => {
            if(product.type == 'basic_element') {
                primaryProducts.push(product)
            }
        });

        return primaryProducts;
    },
    getAccessories(state) {
        let accessories = [];
        
        //when api call is in progress return empty array
        if (!state.applicationData) return [];

        state.applicationData.products.forEach(product => {
            if(product.type == 'accessories') {
                accessories.push(product)
            }
        });

        return accessories;
    },
    getAccessoriesByGroups(state) {
        let accessories = {};
        
        //when api call is in progress return empty array
        if (!state.applicationData) return [];

        state.applicationData.products.forEach(product => {
            if(product.type == 'accessories') {
                !(product.group in accessories) && (accessories[product.group] = [])
                accessories[product.group].push(product)
            }
        });

        return accessories;
    },
    getAccessoriesAndConnectorsByGroups(state) {
        let accessories = {};
        
        //when api call is in progress return empty array
        if (!state.applicationData) return [];

        state.applicationData.products.forEach(product => {
            if(product.type == 'accessories' || product.type == 'connectors') {
                !(product.group in accessories) && (accessories[product.group] = [])
                accessories[product.group].push(product)
            }
        });

        // Sort accesories as they are sorted on JF webpage
        let sortedAccessories = {};

        sortedAccessories = {
            connectors : accessories.connectors,
            screw_joints : accessories.screw_joints,
            anchoring_solutions : accessories.anchoring_solutions,
            side_bumpers : accessories.side_bumpers,
            ladders_and_slides : accessories.ladders_and_slides,
            fences : accessories.fences,
            winches : accessories.winches,
        }
        
        return sortedAccessories;
    },
    getInventory(state) {
        if (!state.applicationData) return [];
        return state.applicationData.products;
    },
    getInventoryConnectors(state) {
        if (!state.applicationData) return [];
        return state.applicationData.products.filter(item => item.group == "connectors").reduce((acc, item) => {
            acc[item.name] = {
              id: item.id,  
              type: item.type,
              ruleset: item.rule,
              size: constants.ELEMENT_SIZES[item.rule],
              sku: item.variations.reduce((acc, item)=>{
                acc[item.color] = item.sku;
                return acc;
              }, {}),
              connections: constants.ELEMENT_CONNECTIONS[item.rule],
            }
            return acc;
          }, {});
    },
    getInventoryDefaultConnectors(state) {
        if (!state.applicationData) return [];

        return state.applicationData.products.filter(item => helpers.isDefaultConnector(item.rule) == true).reduce((acc, item) => {
            acc[item.name] = {
              id: item.id,
              type: item.type,
              rule: item.rule,
              size: constants.ELEMENT_SIZES[item.rule],
              sku: item.variations.reduce((acc, item)=>{
                acc[item.color] = item.sku;
                return acc;
              }, {}),
              connections: constants.ELEMENT_CONNECTIONS[item.rule],
            }
            return acc;
          }, {});
    },
    getInventoryDefaultConnectorsConnectingPin(state) {
        if (!state.applicationData) return [];

        return state.applicationData.products.filter(item => helpers.isDefaultConnectingPin(item.rule) == true).reduce((acc, item) => {
            return {
                id: item.id,
                name: item.name,
                type: item.type,
                rule: item.rule,
                size: constants.ELEMENT_SIZES[item.rule],
                sku: item.variations.reduce((acc, item)=>{
                    acc[item.color] = item.sku;
                    return acc;
                }, {}),
                connections: constants.ELEMENT_CONNECTIONS[item.rule],
            }
          }, {});
    },
    getInventoryDefaultSideScrew(state) {
        if (!state.applicationData) return [];

        return state.applicationData.products.filter(item => helpers.isDefaultSideScrew(item.rule) == true).reduce((acc, item) => {
            return {
                id: item.id,
                name: item.name,
                type: item.type,
                rule: item.rule,
                size: constants.ELEMENT_SIZES[item.rule],
                sku: item.variations.reduce((acc, item)=>{
                    acc[item.color] = item.sku;
                    return acc;
                }, {}),
                connections: constants.ELEMENT_CONNECTIONS[item.rule],
            }
          }, {});
    },
    getCountries(state) {
        return state.applicationData.countries
    },
    getWaterTypes(state) {
        return state.applicationData.project.constants.water_types
    },
    getSectors(state) {
        return state.applicationData.sectors
    },
    getUnderwaterTypes(state) {
        return state.applicationData.project.constants.underwater_types
    },
    getShoreTypes(state) {
        return state.applicationData.project.constants.shore_types
    },
    getCustomerTypes(state) {
        return state.applicationData.project.constants.customer_types
    }
};

const actions = {
    async getApplicationData({ state, getters }) {
        await axios.get(globals.api.general.app.data)
            .then((response) => {
                this.commit('api/setApplicationData', response.data.data)
                
                //preset shape and variation
                let defaultPrimaryItem = getters.getPrimaryItems[0]
                let item = defaultPrimaryItem
                let variation = defaultPrimaryItem.variations[0]
                this.commit('configurator/setDefaultItemAndVariation', {item , variation})
                let userDataFromCookie = getCookie('userData')
                if (userDataFromCookie != "") {
                    state.userData = JSON.parse(userDataFromCookie)
                    this.commit('projectData/setVendor', state.userData.vendor_id)
                }
            })
            .catch(() => {
                router.push({ name: 'api-error' })
            });
    },
    // eslint-disable-next-line no-empty-pattern
    async loadApplicationData({ }, refNumber) {
        await axios.get(globals.api.general.app.loadProject + "?referenceNumber=" + refNumber)
            .then((response) => {
                let payload = response.data.data
                if (!payload) {
                    throw new Error("missing-project")
                }
                if (payload.is_new == 0) {
                    throw new Error("old-project")
                }
                this.commit('projectData/setMode', 'edit')
                this.commit('projectData/setRefNumber', refNumber)
                this.commit('projectData/setRefId', payload.id)
                this.commit('projectData/setProjectName', payload.name)
                this.commit('projectData/setProjectDescription', payload.description)
                this.commit('projectData/setProjectCountry', payload.country)
                this.commit('projectData/setSector', payload.sector)
                this.commit('projectData/setSectorApplication', payload.sector_application)
                this.commit('projectData/setProjectConditions', payload.conditions)
                this.commit('projectData/setCustomer', payload.customer)
                this.commit('projectData/setConfiguration', JSON.parse(payload.configuration))
            })
    }
};

const mutations = {
    setApplicationData(state , newApplicationData) {
        state.applicationData = newApplicationData
        state.inventory = newApplicationData.products
    }
};

//const methods = {};

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};