export default class GridPoint {
    x = null;
    y = null;
    level = null;

    constructor(x, y, level = 0) {
        this.x = x;
        this.y = y;
        this.level = level;
    }

    move(x, y) {
        return new GridPoint(this.x + x, this.y + y, this.level);
    }

    getNeighbourPoints() {
        let points = [];
        points.push(new GridPoint(this.x - 1, this.y - 1, this.level)); // Levo zgori
        points.push(new GridPoint(this.x + 1, this.y - 1, this.level)); // Levo zgori
        points.push(new GridPoint(this.x, this.y - 1, this.level)); // Zgoraj
        points.push(new GridPoint(this.x + 1, this.y, this.level)); // Desno
        points.push(new GridPoint(this.x - 1, this.y, this.level)); // Levo
        points.push(new GridPoint(this.x, this.y + 1, this.level)); // Spodaj
        points.push(new GridPoint(this.x + 1, this.y + 1, this.level)); // Desno spodi
        points.push(new GridPoint(this.x - 1, this.y + 1, this.level)); // Levo spodi
        return points;
    }

    getNeighbourSquaresAroundPoint() {
        let points = [];
        points.push(new GridPoint(this.x, this.y, this.level)); // self
        points.push(new GridPoint(this.x - 1, this.y, this.level)); // Desno
        points.push(new GridPoint(this.x, this.y - 1, this.level)); // Spodaj
        points.push(new GridPoint(this.x - 1, this.y - 1, this.level)); // Levo spodi
        return points;
    }

    // Neighbours of the location user clicks to place a sidebumper
    getSidebumperNeighbourSquares(rotation, width, height) {
        let points = [];

        // If height and width are 1 element will still take 2 connections so 1 space must be between
        switch (rotation) { // CONSTANT IMPORT FAILED 
            case 0: //constants.ORIENTATION_LEFT:
            case 2: //constants.ORIENTATION_RIGHT:
                points.push(new GridPoint(this.x, this.y + (1 * height), this.level)); // Down 
                points.push(new GridPoint(this.x, this.y - 1, this.level)); // Up
                break;
            case 1: //constants.ORIENTATION_TOP:
            case 3: //constants.ORIENTATION_BOTTOM:
                points.push(new GridPoint(this.x + (1 * width), this.y, this.level)); // Rigth
                points.push(new GridPoint(this.x - 1, this.y, this.level)); // Left
                break;
        }

        return points;
    }

    // Neighbours of the location user clicks to place a sidebumper
    getOutsideCornerSidebumperNeighbourSquares(rotation) {
        let points = [];

        switch (rotation) {
            case 0:
            case 1:
                points.push(new GridPoint(this.x, this.y, this.level));
                points.push(new GridPoint(this.x-1, this.y, this.level));
                points.push(new GridPoint(this.x, this.y-1, this.level));
                points.push(new GridPoint(this.x-1, this.y-1, this.level));
                break;
            case 2: 
            case 3: 
                points.push(new GridPoint(this.x, this.y, this.level));
                points.push(new GridPoint(this.x+1, this.y, this.level));
                points.push(new GridPoint(this.x, this.y-1, this.level));
                points.push(new GridPoint(this.x+1, this.y-1, this.level));
                break;
        }

        return points;
    }

    getInsideCornerSidebumperPrimaryConnectionPoint(rotation) {
        switch (rotation) {
            case 0:
                return new GridPoint(this.x+1, this.y, this.level);

            case 1:
                return new GridPoint(this.x+1, this.y+1, this.level);

            case 2: 
                return new GridPoint(this.x, this.y+1, this.level);
            
            case 3: 
                return new GridPoint(this.x, this.y, this.level);
        }
    }

    getSidebumperNeighbourSquaresForConnection(rotation) {
        let points = [];

        switch (rotation) { // CONSTANT IMPORT FAILED 
            case 0: //constants.ORIENTATION_LEFT:
                points.push(new GridPoint(this.x - 1, this.y, this.level)); // Left
                points.push(new GridPoint(this.x - 1, this.y - 1, this.level)); // Left and down
                break;
            case 1: //constants.ORIENTATION_TOP:
                points.push(new GridPoint(this.x - 1, this.y - 1, this.level)); // Up and left
                points.push(new GridPoint(this.x, this.y - 1, this.level)); // Up
                break;
            case 2: //constants.ORIENTATION_RIGHT: // When placing on the right no movement neded as all points are right oriented
                points.push(new GridPoint(this.x, this.y, this.level)); // Self
                points.push(new GridPoint(this.x, this.y - 1, this.level)); // Self and up
                break;
            case 3: //constants.ORIENTATION_BOTTOM:
                points.push(new GridPoint(this.x, this.y , this.level)); // Self
                points.push(new GridPoint(this.x -1 , this.y, this.level)); // Back
                break;
        }

        return points;
    }

    getPileCageEdgeNeighbours(rotation, width, height) {
        let points = [];        

        // If height and width are 1 element will still take 2 connections so 1 space must be between
        switch (rotation) {
            case 0:
            case 2:
                points.push(new GridPoint(this.x, this.y + (1 * height), this.level)); // Down 
                points.push(new GridPoint(this.x, this.y - 1, this.level)); // Up
                break;
            case 1:
            case 3:
                points.push(new GridPoint(this.x + (1 * width), this.y, this.level)); // Rigth
                points.push(new GridPoint(this.x - 1, this.y, this.level)); // Left
                break;
        }

        return points;
    }

    getLadderCheckPosition(rotation) {
        switch (rotation) {
            case 0:
                return new GridPoint(this.x+1, this.y, this.level);

            case 1:
                return new GridPoint(this.x, this.y+1, this.level);

            case 2: 
                return new GridPoint(this.x-1, this.y, this.level);
            
            case 3: 
                return new GridPoint(this.x, this.y-1, this.level);
        }
    }

    toString() { 
        return `${this.x}x${this.y}-${this.level}`;
    }
}
