export default class GridSize {
    width = null;
    height = null;

    constructor(width = null, height = null) {
        this.width = width;
        this.height = height;
    }

    getAreaPoints(startPoint) {
        let points = []
        // This logic dosent work if element has X more than 1 width for exaple in 3x2 it will return only 3 points 
        // TODO Mark če probaš pile cage max 500 outer k ma 3x2 dimenzije ta logika vrne ful premalo točk 
        // msz da je delal do zdele tole samo zato ker je bil to realno 1 primer k je mel X večji k 1
        /*
        for (let x = 0; x < this.width; x++) {
            points.push(startPoint.move(x, 0))
        }
        for (let y = 1; y < this.height; y++) {
            points.push(startPoint.move(0, y))
        }*/

        for(let offsetX = 0; offsetX < this.width; offsetX++) {
            for(let offsetY = 0; offsetY < this.height; offsetY++) {
                points.push(startPoint.move(offsetX, offsetY))
            }
        }

        return points;
    }

    rotate() {
        return new GridSize(this.height, this.width);
    }
}
