import Vue from 'vue';
import Vuex from 'vuex';
import basicData from './modules/basic-data';
import configurator from './modules/configurator';
import api from './modules/api';
import projectData from './modules/project-data';

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: false,
    modules: {
        basicData,
        configurator,
        api,
        projectData,
    },
    state: {

    },
    mutations: {
        
    },
});

export default store;