import _ from 'lodash';
import GridPoint from "@/components/configurator/Editor/lib/Cordinates/GridPoint";

const state = () => ({
    basicBlock: "double",
    color: "black",
    shape: "rectangle",
    shapeInputs: {
        a: 0,
        b: 0,
        c: 0,
        d: 0,
    },
    shapeInputRules: {
        rectangle: ["a", "b"],
        tform: ["a", "b", "c", "d"],
        square: ["a"],
        uform: ["a", "b", "c", "d"],
        empty: [],
    },
    basicShapeConfiguration: [],
})

const getters = {
    getColor(state) {
        return state.color
    },
    getShape(state) {
        return state.shape
    },
    getBasicBlock(state) {
        return state.basicBlock
    },
    /**
     * 
     * @param {*} state 
     * @param {string} input 
     * 
     * If input is null it return object of all inputs
     * If input is a string it will return the value of the key represented by input
     */
    getShapeInputs(state) {
        return state.shapeInputs
    },
    getShapeInputRules(state) {
        return state.shapeInputRules
    },
    shapeInputsTouched(state) {
        return !_.isEqual(state.shapeInputs, {
            a: 0,
            b: 0,
            c: 0,
            d: 0,
        })
    },
    getBasicShapeConfigurationPoints(state) {
        return state.basicShapeConfiguration;
    },
    getConfiguratorLines(state, getters, rootState, rootGetters) {
        return rootGetters['configurator/getNLines']
    },
    getConfiguratorLevel(state, getters, rootState, rootGetters) {
        return rootGetters['configurator/getLevel']
    }
};

const actions = {
    setupBasicShapeConfiguration({ commit, getters, state }) {
        // Transfrom from FT to M if user is using imperial system
        let userInputs = {...state.shapeInputs}

        let startingCoordinate = getters.getConfiguratorLines / 2
        let points = []

        // For Tdocks 1 square represents 48.5cm so we need to transform meters to CM for now just multiply by 2
        function userInputToMeters(input) {
            return input * 2
        }
        
        switch (state.shape) {
            case 'rectangle': {
                let amountOfXSteps = userInputToMeters(userInputs["a"])
                let amountOfYSteps = userInputToMeters(userInputs["b"])

                for(let y=0; y < amountOfYSteps; y++) { // movement on y axis
                    for(let x=0; x < amountOfXSteps; x++) { // movement on x axis
                        points.push(new GridPoint(x + startingCoordinate, y + startingCoordinate - amountOfYSteps, getters.getConfiguratorLevel))
                    }
                }
                break;
            }
            case 'tform': {
                let totalHeight =  userInputToMeters(userInputs["a"]) + userInputToMeters(userInputs["c"]);

                // Left part of T
                let amountOfXSteps = userInputToMeters(userInputs["b"])
                let amountOfYSteps = userInputToMeters(userInputs["a"])

                for(let y=0; y < amountOfYSteps; y++) { // movement on y axis
                    for(let x=0; x < amountOfXSteps; x++) { // movement on x axis
                        points.push(new GridPoint(x + startingCoordinate, y + startingCoordinate - totalHeight, getters.getConfiguratorLevel))
                    }
                }

                // Middle part of T
                let offsetX = amountOfXSteps
                amountOfXSteps = userInputToMeters(userInputs["d"])
                amountOfYSteps = userInputToMeters(userInputs["a"]) + userInputToMeters(userInputs["c"])

                for(let y=0; y < amountOfYSteps; y++) { // movement on y axis
                    for(let x=0; x < amountOfXSteps; x++) { // movement on x axis
                        points.push(new GridPoint(x + startingCoordinate + offsetX, y + startingCoordinate - totalHeight, getters.getConfiguratorLevel))
                    }
                }
                
                // Right part of T
                offsetX +=amountOfXSteps
                amountOfXSteps = userInputToMeters(userInputs["b"])
                amountOfYSteps = userInputToMeters(userInputs["a"])

                for(let y=0; y < amountOfYSteps; y++) { // movement on y axis
                    for(let x=0; x < amountOfXSteps; x++) { // movement on x axis
                        points.push(new GridPoint(x + startingCoordinate + offsetX, y + startingCoordinate - totalHeight, getters.getConfiguratorLevel))
                    }
                }

                break;
            }
            case 'square': {
                let amountOfSteps = userInputs["a"] * 2

                for(let y=0; y < amountOfSteps; y++) { // movement on y axis
                    for(let x=0; x < amountOfSteps; x++) { // movement on x axis
                        points.push(new GridPoint(x + startingCoordinate, y + startingCoordinate  - amountOfSteps, getters.getConfiguratorLevel))
                    }
                }
                break;
            }
            case 'uform': {
                let totalHeight =  userInputToMeters(userInputs["b"]);

                // Left part of U
                let amountOfXSteps = userInputToMeters(userInputs["a"])
                let amountOfYSteps = userInputToMeters(userInputs["b"])

                for(let y=0; y < amountOfYSteps; y++) { // movement on y axis
                    for(let x=0; x < amountOfXSteps; x++) { // movement on x axis
                        points.push(new GridPoint(x + startingCoordinate, y + startingCoordinate - totalHeight, getters.getConfiguratorLevel))
                    }
                }

                // Middle part of U
                let offsetX = amountOfXSteps
                let offsetY = userInputToMeters(userInputs["b"]) - userInputToMeters(userInputs["d"])
                amountOfXSteps = userInputToMeters(userInputs["c"])
                amountOfYSteps = userInputToMeters(userInputs["d"])

                for(let y=0; y < amountOfYSteps; y++) { // movement on y axis
                    for(let x=0; x < amountOfXSteps; x++) { // movement on x axis
                        points.push(new GridPoint(x + startingCoordinate + offsetX, y + startingCoordinate + offsetY - totalHeight, getters.getConfiguratorLevel))
                    }
                }

                // Right part of U
                offsetX = userInputToMeters(userInputs["a"]) + userInputToMeters(userInputs["c"])
                amountOfXSteps = userInputToMeters(userInputs["a"])
                amountOfYSteps = userInputToMeters(userInputs["b"])

                for(let y=0; y < amountOfYSteps; y++) { // movement on y axis
                    for(let x=0; x < amountOfXSteps; x++) { // movement on x axis
                        points.push(new GridPoint(x + startingCoordinate + offsetX, y + startingCoordinate - totalHeight, getters.getConfiguratorLevel))
                    }
                }

                break;
            }
            default:
                throw 'Unrecognised shape!'

            //this.commit('projectData/setConfiguration', elements) //We will let the configurator and configurator only fill the configuration field
        }

        commit('setBasicShapeConfiguration', points)
    },
    cleanBasicShapeConfiguration({ state }) {
        state.basicShapeConfiguration = [];
    }
};

const mutations = {
    setColor(state, newColor) {
        state.color = newColor
    },
    setShape(state, newShape) {
        state.shape = newShape
    },
    
    setBasicBlock(state, newBlock) {
        state.basicBlock = newBlock
    },
    /**
     * 
     * @param {*} state 
     * @param {int|object} newInput 
     * @param {string} input 
     * 
     * If input is specified then only set value for theat key 
     * If input is not specefied then ovveride whole object
     */
    setShapeInputs(state, newInput, input = null) {
        if (!input) {
            state.shapeInputs = newInput
        } else {
            state.shapeInputs[input] = newInput
        }
    },
    setBasicShapeConfiguration(state, newInput) {
        state.basicShapeConfiguration = newInput;
    }
};

//const methods = {};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};